import ShowcaseSection from '@/components/blocks/Showcase'
import { useEffect, useState } from 'react'
import { motion } from 'framer-motion'

const insightSections = [
    {
        title: 'Live Preview Monitoring',
        description:
            'See every frame get rendered live as they finish and download them individually or all at once as they finish.',
        imageSrc: '/images/assets/square.svg',
        imageAlt: '',
    },
    {
        title: 'Actionable Logs and Insights',
        description:
            'Take action with our smart error detection and fix up scenes before committing to a full rendering or even during rendering.',
        imageSrc: '/images/assets/square.svg',
        imageAlt: '',
    },
]

const insightLearnMoreProps = {
    href: '/',
    text: 'Learn more about Insight',
}

const insightShowcaseHeader = {
    title: 'Insight',
    subtitle: 'Render with visibility and confidence every frame.',
    description:
        'Monitor your render at every step of the process to ensure every frame is the way you want it.',
}

export default function InsightShowcaseSection() {
    return (
        <section
            id='insight'
            className='mb-4 px-4 text-center md:max-w-5xl md:px-12'
        >
            <ShowcaseSection
                variant='insight'
                headerProps={insightShowcaseHeader}
                cardProps={insightSections}
                learnMoreProps={insightLearnMoreProps}
                cards={[<InsightCardOne />, <InsightCardTwo />]}
            ></ShowcaseSection>
        </section>
    )
}

import { useRef } from 'react'

const StaggeredFrameBoxes = () => {
    const [elements, setElements] = useState<React.JSX.Element[]>([null, null])
    const intervalRef = useRef<NodeJS.Timeout | null>(null)
    useEffect(() => {
        intervalRef.current = setInterval(() => {
            // Logic to be executed every 3000ms
            setElements((prev) => [...prev, null])
        }, 4000)
        if (elements.length >= 9) {
            setElements([null, null])
            clearInterval(intervalRef.current)
        }
        return () => clearInterval(intervalRef.current)
    }, [elements])
    return (
        <div className='w-full h-full'>
            <motion.div className='items-center grid grid-cols-4 grid-rows-2 w-full justify-center gap-x-2 gap-y-4 py-24 px-8 rounded-md'>
                {elements.map((element, index) => (
                    <motion.div
                        key={index}
                        className='aspect-square rounded-sm h-12 w-12 grid items-center bg-neutral-500'
                        initial='hidden'
                        animate='visible'
                        whileHover='hover'
                        whileTap='visible'
                        whileFocus='hover'
                        variants={{
                            visible: {
                                opacity: 1,
                                borderRadius: '10%',
                                transition: {
                                    duration: 1,
                                    type: 'spring',
                                },
                            },
                            hidden: {
                                opacity: 0,
                                borderRadius: '80%',
                                transition: {
                                    duration: 1,
                                    type: 'spring',
                                },
                            },
                            hover: {
                                background:
                                    'linear-gradient(45deg, rgba(16, 185, 129, 1) 0%, rgb(6, 78, 59) 100%)',
                                boxShadow: '0 0 3px 0 rgba(0,0,0, 0.25)',
                                scale: 1.05,
                                borderRadius: '10%',
                                transition: {
                                    duration: 0.3,
                                },
                            },
                        }}
                    >
                        <div>
                            <h6 className='text-white text-xs'>Frame</h6>
                            <h6 className='text-white text-xs'>{index + 1}</h6>
                        </div>
                    </motion.div>
                ))}
            </motion.div>
        </div>
    )
}

const InsightCardOne = () => {
    return (
        <div className='w-full h-full'>
            <StaggeredFrameBoxes />
        </div>
    )
}

const InsightCardTwo = () => {
    return (
        <div className='px-8 py-24 w-full h-full flex justify-center items-center'>
            <CardStack items={items} />
        </div>
    )
}

let interval: any

const ErrorLogMessage = () => {
    return (
        <div className='flex flex-col gap-1'>
            <span className='text-md font-medium text-orange-200 mb-3'>
                Uh-oh, are you missing these texture files?
            </span>
            <div className='flex flex-col gap-1'>
                <code className='text-xs'>/textures/hdr-bg-fhd_02.png</code>
                <code className='text-xs'>/textures/marble-4k.png</code>
                <code className='text-xs'>/textures/hdr-bg-fhd.png</code>
            </div>
        </div>
    )
}

const ErrorLogMessageTwo = () => {
    return (
        <div className='flex flex-col gap-1'>
            <span className='text-md font-medium text-orange-200 mb-3'>
                Did you forget to set a camera?
            </span>
            <div className='flex flex-col gap-1'>
                <code className='text-xs'>No camera found in scene</code>
            </div>
        </div>
    )
}

const ErrorLogMessageThree = () => {
    return (
        <div className='flex flex-col gap-1'>
            <span className='text-md font-medium text-orange-200 mb-3'>
                Your compositing tree isn't set up correctly.
            </span>
            <div className='flex flex-col gap-1'>
                <code className='text-xs'>
                    No output node found in compositing tree
                </code>
            </div>
        </div>
    )
}

const items = [
    {
        id: 1,
        name: 'Missing Texture',
        content: <ErrorLogMessage />,
    },
    {
        id: 2,
        name: 'Missing Camera',
        content: <ErrorLogMessageTwo />,
    },
    {
        id: 3,
        name: 'Mismatch of Compositing Tree',
        content: <ErrorLogMessageThree />,
    },
]

type Card = {
    id: number
    name: string
    designation: string
    content: React.ReactNode
}

export const CardStack = ({
    items,
    offset,
    scaleFactor,
}: {
    items: Card[]
    offset?: number
    scaleFactor?: number
}) => {
    const CARD_OFFSET = offset || 10
    const SCALE_FACTOR = scaleFactor || 0.06
    const [cards, setCards] = useState<Card[]>(items)

    useEffect(() => {
        startFlipping()

        return () => clearInterval(interval)
    }, [])
    const startFlipping = () => {
        interval = setInterval(() => {
            setCards((prevCards: Card[]) => {
                const newArray = [...prevCards] // create a copy of the array
                newArray.unshift(newArray.pop()!) // move the last element to the front
                return newArray
            })
        }, 2500)
    }

    return (
        <div className='relative h-48 w-48 md:h-48 md:w-48'>
            {cards.map((card, index) => {
                return (
                    <motion.div
                        key={card.id}
                        className='absolute dark:bg-black bg-white h-48 w-48 md:h-48 md:w-48 rounded-3xl p-4 shadow-xl border border-neutral-200 dark:border-white/[0.1]  shadow-black/[0.1] dark:shadow-white/[0.05] flex flex-col justify-between'
                        style={{
                            transformOrigin: 'top center',
                        }}
                        animate={{
                            top: index * -CARD_OFFSET,
                            scale: 1 - index * SCALE_FACTOR, // decrease scale for cards that are behind
                            zIndex: cards.length - index, //  decrease z-index for the cards that are behind
                        }}
                    >
                        <div key={card.id}>{card.content}</div>
                    </motion.div>
                )
            })}
        </div>
    )
}
